import { DialogController }                 from 'aurelia-dialog';
import { bindable, inject }                 from 'aurelia-framework';
import { PLATFORM }                         from 'aurelia-pal';
import { BaseFormViewModel }                from 'base-form-view-model';
import { SpecificationRevisionFormSchema }  from 'modules/management/specifications/specifications/specification-revisions/form-schema';
import { SpecificationRevisionsRepository } from 'modules/management/specifications/specifications/specification-revisions/services/repository';
import { AppContainer }                     from 'resources/services/app-container';

@inject(AppContainer, DialogController, SpecificationRevisionsRepository, SpecificationRevisionFormSchema)
export class EditSpecificationRevisionModal extends BaseFormViewModel {

    formId = 'management.specifications.specifications.revisions.edition-form';

    @bindable alert = {};
    model           = {};
    schema          = {};

    @bindable settings = {
        title:   'form.title.edit-record',
        size:    'modal-full',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, dialogController, repository, formSchema) {
        super(appContainer);

        this.dialogController = dialogController;
        this.repository       = repository;
        this.formSchema       = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.specifications.manage',
            'management.specifications.specifications.edit',
        ]);
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/specifications/specifications/specification-revisions/form.html');
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.concessionId = params.concessionId;

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return await this.fetchData(params);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    submit() {
        this.alert = null;

        this.repository
            .update(this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response);
                this.initialModel.assign(this.model);

                if (response.status === true) {
                    this.dialogController.ok();
                }
            })
            .catch((error) => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
