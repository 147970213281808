import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class RevisionMaterialTypesRepository extends BaseRepository {
    baseUrl                  = 'management/specifications/specifications/revisions/material-types';
    specificationRevisionUrl = 'management/specifications/specifications/revisions';

    /**
     * Get all listings associated to sector
     * @param sectorId
     * @param criteria
     */
    allFromSector(sectorId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/all-from-sector/${sectorId}`, criteria);
    }

    /**
     * Get all listings associated to sector
     */
    groupedAll() {
        return this.httpClient.get(`${this.baseUrl}/grouped-all`);
    }

    /**
     * Performs a search given some criteria
     *
     * @param specificationRevisionId
     * @param criteria
     *
     * @returns {*}
     */
    search(specificationRevisionId, criteria) {
        return this.httpClient.post(`${this.specificationRevisionUrl}/${specificationRevisionId}/material-types/search`, criteria);
    }

    /**
     * Creates a record
     *
     * @param specificationRevisionId
     * @param data
     *
     * @returns {*}
     */
    create(specificationRevisionId, data) {
        return this.httpClient.post(`${this.specificationRevisionUrl}/${specificationRevisionId}/material-types`, { ...data, specification_revision_id: specificationRevisionId });
    }
}
